import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {merge} from 'lodash-es';
import {FuseConfirmationDialogComponent} from '@fuse/services/confirmation/dialog/dialog.component';
import {FuseConfirmationConfig} from '@fuse/services/confirmation/confirmation.types';
import {TranslocoService} from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class FuseConfirmationService {
  private _defaultConfig: FuseConfirmationConfig;

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private translocoService: TranslocoService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
    this._defaultConfig = {
      title: this.translocoService.translate('Confirm action'),
      message: this.translocoService.translate('Are you sure you want to confirm this action?'),
      icon: {
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'warn',
      },
      actions: {
        confirm: {
          show: true,
          label: this.translocoService.translate('ALERT.Confirm'),
          color: 'warn',
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('ALERT.Cancel'),
        },
      },
      dismissible: false,
    };
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(FuseConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'fuse-confirmation-dialog-panel',
    });
  }
}
