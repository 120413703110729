/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { MobileApiOrderV2 } from '../models/MobileApiOrderV2';
import type { Paginated_MobileApiOrderV2_ } from '../models/Paginated_MobileApiOrderV2_';
import type { PgOrder } from '../models/PgOrder';
import type { QuerySpec } from '../models/QuerySpec';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OrderService {

    constructor(private readonly http: HttpClient) {}

    /**
     * All orders by date
     * @param last
     * @param limit
     * @returns Paginated_MobileApiOrderV2_ Ok
     * @throws ApiError
     */
    public listOrdersV1(
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_MobileApiOrderV2_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/order',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgOrder Ok
     * @throws ApiError
     */
    public searchOrdersV1(
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgOrder>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/order',
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orderId
     * @returns MobileApiOrderV2 Ok
     * @throws ApiError
     */
    public getOrderV1(
        orderId: string,
    ): Observable<MobileApiOrderV2> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/order/{orderId}',
            path: {
                'orderId': orderId,
            },
        });
    }

}