/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { MerchantWithDetails } from '../models/MerchantWithDetails';
import type { Paginated_MerchantListItem_ } from '../models/Paginated_MerchantListItem_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * Possible queries::
     * - VIEW_ALL
     * - BY PARTICULAR NIP
     * - ONLY_CLOSED_PLATFORM
     * ...
     * @returns Paginated_MerchantListItem_ Ok
     * @throws ApiError
     */
    public listMerchants(): Observable<Paginated_MerchantListItem_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants',
        });
    }

    /**
     * @param merchantId
     * @returns MerchantWithDetails Ok
     * @throws ApiError
     */
    public getMerchant(
        merchantId: string,
    ): Observable<MerchantWithDetails> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}',
            path: {
                'merchantId': merchantId,
            },
        });
    }

}