/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class KycClearService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public clear(
        requestBody: {
            phoneNumber: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/clear',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}