<form *transloco="let t; prefix: 'ONBOARDING_MERCHANT_DETAILS'" [formGroup]="form" class="w-full pt-3 p-8"
  (ngSubmit)="onSubmit()">


  <div *ngIf="form" class="scrollable-area">

    <ng-container formGroupName="details">

      <!-- CompanyName field -->

      <div class="text-xl mb-3 mt-4"><strong>{{ t('Address') }}:</strong></div>

      <div class="grid sm:grid-cols-4 w-full items-center" formGroupName="address">


        <div class="sm:col-span-2 pr-4">

          <div class="grid sm:grid-cols-6 w-full items-center">
            <div class="sm:col-span-2 pb-4 text-sm">{{ t('Street') }}:</div>
            <div class="sm:col-span-4">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('street') }">
                  </ng-container>
                </span>
                <input matInput formControlName="street" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>


            <div class="sm:col-span-2 pb-4 text-sm">{{ t('City') }}:</div>
            <div class="sm:col-span-4">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('city') }">
                  </ng-container>
                </span>
                <input matInput formControlName="city" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>

          </div>
        </div>

        <div class="sm:col-span-1 pl-4 pr-4">

          <div class="grid sm:grid-cols-6 w-full items-center">
            <div class="sm:col-span-3 pb-4 text-sm">{{ t('Street number') }}:</div>
            <div class="sm:col-span-3">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('streetNo') }">
                  </ng-container>
                </span>
                <input matInput formControlName="streetNo" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>

            <div class="sm:col-span-3 pb-4 text-sm">{{ t('Postal code') }}:</div>
            <div class="sm:col-span-3">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('postalCode') }">
                  </ng-container>
                </span>
                <input matInput formControlName="postalCode" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>
          </div>
        </div>


        <div class="sm:col-span-1 pl-4">

          <div class="grid sm:grid-cols-6 w-full items-center">
            <div class="sm:col-span-3 pb-4 text-sm">{{ t('Apartment number') }}:</div>
            <div class="sm:col-span-3">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('apartmentNo') }">
                  </ng-container>
                </span>
                <input matInput formControlName="apartmentNo" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>

            <div class="sm:col-span-3 pb-4 text-sm">{{ t('Country') }}:</div>
            <div class="sm:col-span-3">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: addressCtrl.get('country') }">
                  </ng-container>
                </span>
                <input matInput formControlName="country" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>



          </div>
        </div>

      </div>

      <div class="grid sm:grid-cols-4 w-full items-center">
        <div class="sm:col-span-2 pr-4">

          <div class="grid sm:grid-cols-6 w-full items-center">

            <div class="sm:col-span-3 pb-4 text-sm">{{ t('Website') }}:</div>
            <div class="sm:col-span-3">
              <mat-form-field class="w-full">
                <span matSuffix>
                  <ng-container
                    *ngTemplateOutlet="validationResultsIcons; context: { $implicit: detailsCtrl.get('websiteUrl') }">
                  </ng-container>
                </span>
                <input matInput formControlName="websiteUrl" [disabled]="form.disabled || loading" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>


      <div class="flex justify-between mt-4 mb-3">
        <div class="col-span-3">
          <div class="text-xl mb-3 mt-4 text-sm"><strong>{{ t('Representatives') }}:</strong></div>

        </div>
        <div *ngIf="[CRUDModeOptions.UPDATE, CRUDModeOptions.CREATE].includes(mode)">
          <button mat-mini-fab type="button" color="accent" (click)="addRepresentative()">
            <mat-icon class="icon-size-4 text-red-700" [svgIcon]="'heroicons_solid:plus-sm'"></mat-icon>
          </button>
        </div>
      </div>

      <ol *ngIf="mode === CRUDModeOptions.READ" class="pl-8" [style.list-style]="'auto'">
        <li *ngFor=" let representative of representativesCtrl.controls;" class="mb-3">
          {{representative.value | representativesFormatPipe}}</li>
      </ol>

      <ng-container *ngIf="[CRUDModeOptions.UPDATE, CRUDModeOptions.CREATE].includes(mode)">
        <p *ngIf="!representativesCtrl.controls.length">{{ t('No data to display') }}</p>

        <div *ngFor="let representative of representativesCtrl.controls; let i = index"
          class="grid sm:grid-cols-10 w-full mt-4">

          <ng-container [formGroup]="representative">
            <div class="sm:col-span-3 pr-4">
              <div class="grid sm:grid-cols-6 w-full">
                <div class="sm:col-span-6">

                  <p *ngIf="representative?.get('firstName').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="representative.get('firstName').value"></p>

                  <mat-form-field *ngIf="!representative?.get('firstName').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: representative?.get('firstName') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('firstName') }}" formControlName="firstName" />
                  </mat-form-field>

                  <!-- <p class="pb-5" [innerHTML]="representative.get('firstName').value"></p> -->
                </div>
              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <p *ngIf="representative?.get('lastName').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="representative.get('lastName').value"></p>

                  <mat-form-field *ngIf="!representative?.get('lastName').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container *ngTemplateOutlet="validationResultsIcons; context: { $implicit:
                         representative?.get('lastName') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('lastName') }}" formControlName="lastName" />
                  </mat-form-field>

                </div>

              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <p *ngIf="representative?.get('role').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="representative.get('role').value"></p>

                  <mat-form-field *ngIf="!representative?.get('role').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container *ngTemplateOutlet="validationResultsIcons; context: { $implicit:
                         representative?.get('role') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('Role') }}" formControlName="role" />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <mat-form-field [style.max-height.px]="48" class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: representative.get('pesel') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('Pesel') }}" formControlName="pesel" />
                  </mat-form-field>

                  <div class="mb-2">
                    <mat-checkbox class="text-sm" (click)="disablePeselValidation(representative.get('pesel'))"
                      [color]="'primary'" [checked]="false">
                      Wyłącz walidację PESEL</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>

            <div *ngIf="mode === CRUDModeOptions.UPDATE" class="sm:col-span-1">
              <div class="grid sm:grid-cols-6 w-full items-end">
                <div class="sm:col-span-6">
                  <div *ngIf="representativesCtrl.controls.length > 0" class="flex justify-end">
                    <div class="mt-1">
                      <button mat-mini-fab type="button" color="warn" (click)="removeRepresentative(i)">
                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
        </div>
      </ng-container>



      <div class="flex justify-between mt-4 mb-3">
        <div class="col-span-3">
          <div class="text-xl mb-3 mt-4"><strong>{{ t('Beneficial owners') }}:</strong></div>
        </div>
        <div *ngIf="[CRUDModeOptions.UPDATE, CRUDModeOptions.CREATE].includes(mode)">
          <button mat-mini-fab type="button" color="accent" (click)="addBeneficialOwner()">
            <mat-icon class="icon-size-4 text-red-700" [svgIcon]="'heroicons_solid:plus-sm'"></mat-icon>
          </button>
        </div>
      </div>

      <ol *ngIf="mode === CRUDModeOptions.READ" class="pl-8" [style.list-style]="'auto'">
        <li *ngFor="let benefitialOwner of benefitialOwnersCtrl.controls;" class="mb-3 text-sm">
          {{benefitialOwner.value | representativesFormatPipe}}</li>
      </ol>

      <ng-container *ngIf="[CRUDModeOptions.UPDATE, CRUDModeOptions.CREATE].includes(mode)">

        <p *ngIf="!benefitialOwnersCtrl.controls.length">{{ t('No data to display') }}</p>
        <div *ngFor="let benefitialOwner of benefitialOwnersCtrl.controls; let i = index"
          class="grid sm:grid-cols-10 w-full mt-4 items-start">



          <ng-container [formGroup]="benefitialOwner">

            <div class="sm:col-span-3 pr-4">
              <div class="grid sm:grid-cols-6 w-full">
                <div class="sm:col-span-6">
                  <p *ngIf="benefitialOwner?.get('firstName').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="benefitialOwner.get('firstName').value"></p>

                  <mat-form-field *ngIf="!benefitialOwner?.get('firstName').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: benefitialOwner?.get('firstName') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('firstName') }}" formControlName="firstName" />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <p *ngIf="benefitialOwner?.get('lastName').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="benefitialOwner.get('lastName').value"></p>

                  <mat-form-field *ngIf="!benefitialOwner?.get('lastName').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: benefitialOwner?.get('lastName') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('lastName') }}" formControlName="lastName" />
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <p *ngIf="benefitialOwner?.get('nationality').disabled" class="pb-5 pt-5 text-sm"
                    [innerHTML]="benefitialOwner.get('nationality').value"></p>

                  <mat-form-field *ngIf="!benefitialOwner?.get('nationality').disabled" [style.max-height.px]="48"
                    class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: benefitialOwner?.get('nationality') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('Nationality') }}" formControlName="nationality" />
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div class="sm:col-span-2 pr-4">

              <div class="grid sm:grid-cols-6 w-full items-center">
                <div class="sm:col-span-6">
                  <mat-form-field [style.max-height.px]="48" class="w-full">
                    <span matSuffix>
                      <ng-container
                        *ngTemplateOutlet="validationResultsIcons; context: { $implicit: benefitialOwner?.get('pesel') }">
                      </ng-container>
                    </span>
                    <input matInput placeholder="{{ t('Pesel') }}" formControlName="pesel" />
                  </mat-form-field>
                  <div class="mb-2">
                    <mat-checkbox (click)="disablePeselValidation(benefitialOwner?.get('pesel'))" [color]="'primary'"
                      [checked]="false" class="text-sms">
                      Wyłącz walidację PESEL</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
        </div>
      </ng-container>

      <ng-container *transloco="let e; read: 'ERRORS'">
        <fuse-alert *ngIf="(error$ | async) as error" [type]="'warning'" class="my-3">
          <div class="font-bold text-red-700">{{ error.message }}</div>
          <ul class="ml-5" [style.list-style]="'circle'" *ngIf="error.validationErrors?.length">
            <li *ngFor="let err of error.validationErrors"><span class="font-bold">{{ e(err.path) }}</span> -
              {{e(err.message)}}</li>
          </ul>
        </fuse-alert>
      </ng-container>

      <div *ngIf="mode === CRUDModeOptions.CREATE" class="flex flex-col flex-auto w-80 mx-auto">
        <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'"
          [disabled]="form.disabled || (loading$ | async)" type="submit">
          <span *ngIf="!form.disabled">
            {{ t('Register') }}
          </span>
          <mat-progress-spinner *ngIf="form.disabled || (loading$ | async)" [diameter]="24" [mode]="'indeterminate'">
          </mat-progress-spinner>
        </button>
      </div>

      <div *ngIf="mode === CRUDModeOptions.UPDATE" class="flex flex-col flex-auto w-80 mx-auto">
        <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'"
          [disabled]="form.disabled || (loading$ | async)" type="submit">
          <span *ngIf="!form.disabled">
            {{ t('Save') }}
          </span>
          <mat-progress-spinner *ngIf="form.disabled || (loading$ | async)" [diameter]="24" [mode]="'indeterminate'">
          </mat-progress-spinner>
        </button>
      </div>
    </ng-container>
  </div>

</form>

<ng-template let-data #validationResultsIcons>
  <ng-container *ngIf="data.touched">
    <mat-icon matSuffix *ngIf="data.valid else notValidTmpl" class="icon-size-5 text-green-700"
      [svgIcon]="'heroicons_solid:badge-check'"></mat-icon>
    <ng-template #notValidTmpl>
      <mat-icon class="icon-size-5 text-red-700" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
    </ng-template>
  </ng-container>
</ng-template>