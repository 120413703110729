/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_AccountLedgerEntry_ } from '../models/Paginated_AccountLedgerEntry_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BankAccountLedgerEntriesService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param account
     * @param subAccountNr
     * @param last
     * @param limit
     * @returns Paginated_AccountLedgerEntry_ Ok
     * @throws ApiError
     */
    public list(
        account?: string,
        subAccountNr?: string,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_AccountLedgerEntry_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bankAccountLedgerEntries/list',
            query: {
                'account': account,
                'subAccountNr': subAccountNr,
                'last': last,
                'limit': limit,
            },
        });
    }

}