/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_TopupDirectDebit_ } from '../models/Paginated_TopupDirectDebit_';
import type { TopupDirectDebit } from '../models/TopupDirectDebit';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TopudirectdebitconfigService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param last
     * @param limit
     * @returns Paginated_TopupDirectDebit_ Ok
     * @throws ApiError
     */
    public getTopUpDirectDebitConfigItems(
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_TopupDirectDebit_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/topupdirectdebit',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TopupDirectDebit Ok
     * @throws ApiError
     */
    public createTopUpDirectDebit(
        requestBody: TopupDirectDebit,
    ): Observable<TopupDirectDebit> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/topupdirectdebit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param bank
     * @param requestBody
     * @returns TopupDirectDebit Ok
     * @throws ApiError
     */
    public updateTopUpDirectDebit(
        bank: string,
        requestBody: TopupDirectDebit,
    ): Observable<TopupDirectDebit> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/topupdirectdebit/{bank}',
            path: {
                'bank': bank,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}