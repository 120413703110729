/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { APIGatewayProxyResult } from '../models/APIGatewayProxyResult';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OrderListService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param shopOrderId
     * @param id
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadRefundPdf(
        merchantId: string,
        integrationProfileId: string,
        shopOrderId: string,
        id: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/{merchantId}/{integrationProfileId}/order/{shopOrderId}/refund/{id}/pdf',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'shopOrderId': shopOrderId,
                'id': id,
            },
        });
    }

}