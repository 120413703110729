import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

const pathAllowlist = ['/users/activation', '/login'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  private shouldRedirect(path: string): boolean {
    for (const partial of pathAllowlist) {
      if (path.includes(partial)) {
        return false;
      }
    }

    return true;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    const authToken = this._authService.getToken();

    if (authToken && !AuthUtils.isTokenExpired(authToken)) {
      newReq = req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${authToken}`,
        }),
      });
    }

    var re = new RegExp('/settlements/[\\S]{0,}/[\\S]{0,}/[0-9]{4}-[0-9]{2}-[0-9]{2}');
    var invoice = new RegExp('/invoicing/[\\S]{0,}/[\\S]{0,}/[A-Za-z0-9_]{0,}');
    var paymentHistory = new RegExp('/order/[\\S]{0,}/refund/[\\S]{0,}/pdf');
    var documents = new RegExp('/manualOnboarding/merchants/[\\S]{0,}/documents/[\\S]{0,}$');

    if (req.url.match('/reports/') || re.test(req.url)) {
      newReq = newReq.clone({
        responseType: 'text',
      });
    }

    if (req.method === 'POST' && documents.test(req.url)) {
      newReq = newReq.clone({
        responseType: 'blob',
      });
    }

    if (invoice.test(req.url) || paymentHistory.test(req.url)) {
      newReq = newReq.clone({
        responseType: 'blob',
      });
    }

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && this.shouldRedirect(req.url)) {
          this._authService.signOut();
          location.reload();
        }
        return throwError(error?.error || error);
      })
    );
  }
}
