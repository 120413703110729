/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { KycConfigOverride } from '../models/KycConfigOverride';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class KycConfigOverrideService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns KycConfigOverride Ok
     * @throws ApiError
     */
    public list(): Observable<Array<KycConfigOverride>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/kyc/configOverride',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public add(
        requestBody: KycConfigOverride,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/configOverride',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public edit(
        requestBody: KycConfigOverride,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/kyc/configOverride',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param phoneNumber
     * @returns void
     * @throws ApiError
     */
    public remove(
        phoneNumber: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/kyc/configOverride/{phoneNumber}',
            path: {
                'phoneNumber': phoneNumber,
            },
        });
    }

}