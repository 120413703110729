/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_PspTransactionLog_ } from '../models/Paginated_PspTransactionLog_';
import type { PspIdType } from '../models/PspIdType';
import type { PspTransactionStatus } from '../models/PspTransactionStatus';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ItcardService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param pspId
     * @param status
     * @param last
     * @param limit
     * @returns Paginated_PspTransactionLog_ Ok
     * @throws ApiError
     */
    public listPspTrx(
        pspId: PspIdType,
        status: PspTransactionStatus,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_PspTransactionLog_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/pspTransactionLog/list',
            query: {
                'pspId': pspId,
                'status': status,
                'last': last,
                'limit': limit,
            },
        });
    }

}