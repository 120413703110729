/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AccountLedgerEntry } from '../models/AccountLedgerEntry';
import type { Amount } from '../models/Amount';
import type { AppUserChargeBackRequest } from '../models/AppUserChargeBackRequest';
import type { AppUserRefundRequest } from '../models/AppUserRefundRequest';
import type { AppWallet } from '../models/AppWallet';
import type { AppWalletUser } from '../models/AppWalletUser';
import type { CashoutBankAccountRepoResponseItem } from '../models/CashoutBankAccountRepoResponseItem';
import type { CheckoutConsentV2 } from '../models/CheckoutConsentV2';
import type { CombinedAvatar } from '../models/CombinedAvatar';
import type { Device } from '../models/Device';
import type { ElectronicDeliveryEmail } from '../models/ElectronicDeliveryEmail';
import type { EmojiAvatar } from '../models/EmojiAvatar';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { FizenPaymentStatusResponse } from '../models/FizenPaymentStatusResponse';
import type { FizenTopUp } from '../models/FizenTopUp';
import type { ImageAvatar } from '../models/ImageAvatar';
import type { LocalDate } from '../models/LocalDate';
import type { LocalDateTime } from '../models/LocalDateTime';
import type { NoAvatar } from '../models/NoAvatar';
import type { OpenAppGlobalExceptions } from '../models/OpenAppGlobalExceptions';
import type { OrderFailureType } from '../models/OrderFailureType';
import type { Paginated_AppAnonEmailAddress_ } from '../models/Paginated_AppAnonEmailAddress_';
import type { Paginated_AppWalletTrx_ } from '../models/Paginated_AppWalletTrx_';
import type { Paginated_CashoutsListItem_ } from '../models/Paginated_CashoutsListItem_';
import type { Paginated_EmailForwardHistory_ } from '../models/Paginated_EmailForwardHistory_';
import type { Paginated_EnrichedPaymentRequest_ } from '../models/Paginated_EnrichedPaymentRequest_';
import type { Paginated_FizenTopUp_ } from '../models/Paginated_FizenTopUp_';
import type { Paginated_MobileApiOrderV2_ } from '../models/Paginated_MobileApiOrderV2_';
import type { Paginated_OrderFailure_ } from '../models/Paginated_OrderFailure_';
import type { Paginated_PaymentRequestLog_ } from '../models/Paginated_PaymentRequestLog_';
import type { Paginated_PspTransactionLog_ } from '../models/Paginated_PspTransactionLog_';
import type { Paginated_User_ } from '../models/Paginated_User_';
import type { Paginated_UserBlock_ } from '../models/Paginated_UserBlock_';
import type { ProfileDataResponse } from '../models/ProfileDataResponse';
import type { SavedAddress } from '../models/SavedAddress';
import type { SavedBillingAddress } from '../models/SavedBillingAddress';
import type { SearchUsersResponse } from '../models/SearchUsersResponse';
import type { SendMassPayload } from '../models/SendMassPayload';
import type { SendTargetPayload } from '../models/SendTargetPayload';
import type { SetUserStatusInput } from '../models/SetUserStatusInput';
import type { SharedWalletConfiguration } from '../models/SharedWalletConfiguration';
import type { TopupDirectDebitResponseV3 } from '../models/TopupDirectDebitResponseV3';
import type { User } from '../models/User';
import type { UserIdentity } from '../models/UserIdentity';
import type { UserKycConfig } from '../models/UserKycConfig';
import type { UserRead } from '../models/UserRead';
import type { UserWalletTopup } from '../models/UserWalletTopup';
import type { WalletLimits } from '../models/WalletLimits';
import type { WalletStatus } from '../models/WalletStatus';
import type { WalletType } from '../models/WalletType';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AppusersService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param query
     * @returns Paginated_User_ Ok
     * @throws ApiError
     */
    public findUser(
        query?: string,
    ): Observable<Paginated_User_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/find',
            query: {
                'query': query,
            },
        });
    }

    /**
     * @param start
     * @param end
     * @param last
     * @param limit
     * @returns Paginated_User_ Ok
     * @throws ApiError
     */
    public listUsers(
        start?: LocalDate,
        end?: LocalDate,
        last?: string,
        limit?: number,
    ): Observable<Paginated_User_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/list',
            query: {
                'start': start,
                'end': end,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param query
     * @param offset
     * @param limit
     * @param start
     * @param end
     * @returns SearchUsersResponse Ok
     * @throws ApiError
     */
    public searchUsersV2(
        query?: string,
        offset?: number,
        limit: number = 20,
        start?: LocalDate,
        end?: LocalDate,
    ): Observable<SearchUsersResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v2/appusers/search',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public getUser(
        requestBody: UserRead,
    ): Observable<{
        kycConfig: UserKycConfig;
        wallets: Array<AppWallet>;
        user: User;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/read',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public getUserDetails(
        userId: string,
        requestBody: WithReason,
    ): Observable<{
        topups: TopupDirectDebitResponseV3;
        consents: Array<CheckoutConsentV2>;
        devices: Array<Device>;
        kyc: Array<UserIdentity>;
        profile: ProfileDataResponse;
        bankAccounts: Array<CashoutBankAccountRepoResponseItem>;
        electronicDeliveryEmails: Array<ElectronicDeliveryEmail>;
        wallets: Array<{
            shareAnonymousId?: string;
            sharePublicId?: string;
            walletStatus: WalletStatus;
            limits: WalletLimits;
            cashoutAccount?: string;
            lastTransactionAt: string;
            date: string;
            currentBalance: Amount;
            lastTrx: number;
            timezone: string;
            name: string;
            type: WalletType;
            ownerId: string;
            walletId: number;
            hasAutoTopup: boolean;
            walletAvatar: (NoAvatar | EmojiAvatar | CombinedAvatar | ImageAvatar);
            users: Array<AppWalletUser>;
            configuration: SharedWalletConfiguration;
        }>;
        billing: Array<SavedBillingAddress>;
        addresses: Array<SavedAddress>;
        user: User;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param walletId
     * @param requestBody
     * @param limit
     * @param last
     * @returns Paginated_AppWalletTrx_ Ok
     * @throws ApiError
     */
    public listWalletTransactions(
        userId: string,
        walletId: number,
        requestBody: WithReason,
        limit?: number,
        last?: string,
    ): Observable<Paginated_AppWalletTrx_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/wallet/{walletId}',
            path: {
                'userId': userId,
                'walletId': walletId,
            },
            query: {
                'limit': limit,
                'last': last,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param walletId
     * @param requestBody
     * @returns SharedWalletConfiguration Ok
     * @throws ApiError
     */
    public getWalletConfigurationV1(
        userId: string,
        walletId: number,
        requestBody: WithReason,
    ): Observable<SharedWalletConfiguration> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/wallet/{walletId}/configuration',
            path: {
                'userId': userId,
                'walletId': walletId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createAppUserRefundV1(
        userId: string,
        requestBody: AppUserRefundRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/refund',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param trxId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createAppUserChargeBack(
        userId: string,
        trxId: number,
        requestBody: AppUserChargeBackRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/charge-back/{trxId}',
            path: {
                'userId': userId,
                'trxId': trxId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_MobileApiOrderV2_ Ok
     * @throws ApiError
     */
    public getUserOrders(
        userId: string,
        requestBody: WithReason,
        last?: string,
        limit?: number,
    ): Observable<Paginated_MobileApiOrderV2_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/orders',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param start
     * @param end
     * @param type
     * @param last
     * @param limit
     * @returns Paginated_OrderFailure_ Ok
     * @throws ApiError
     */
    public getUserOrdersFailures(
        userId: string,
        requestBody: WithReason,
        start?: LocalDateTime,
        end?: LocalDateTime,
        type?: Array<OrderFailureType>,
        last?: string,
        limit: number = 20,
    ): Observable<Paginated_OrderFailure_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/orders/failures',
            path: {
                'userId': userId,
            },
            query: {
                'start': start,
                'end': end,
                'type': type,
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_PspTransactionLog_ Ok
     * @throws ApiError
     */
    public getUserPspLogs(
        userId: string,
        requestBody: WithReason,
        last?: string,
        limit?: number,
    ): Observable<Paginated_PspTransactionLog_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/pspLogs',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeUserKyc(
        userId: string,
        requestBody: WithReason,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/kyc/remove',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setUserStatus(
        userId: string,
        requestBody: SetUserStatusInput,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/status',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_FizenTopUp_ Ok
     * @throws ApiError
     */
    public listTopupsForUserV1(
        userId: string,
        requestBody: WithReason,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_FizenTopUp_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/fizen',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param id
     * @returns any Ok
     * @throws ApiError
     */
    public getTopupStatusForUserV1(
        userId: string,
        id: string,
    ): Observable<{
        topup: FizenTopUp;
        status: FizenPaymentStatusResponse;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/{userId}/fizen/{id}',
            path: {
                'userId': userId,
                'id': id,
            },
        });
    }

    /**
     * @param userId
     * @param id
     * @returns AccountLedgerEntry Ok
     * @throws ApiError
     */
    public getTopUpBankDataForUserV1(
        userId: string,
        id: string,
    ): Observable<AccountLedgerEntry> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/{userId}/fizen/{id}/bankdata',
            path: {
                'userId': userId,
                'id': id,
            },
        });
    }

    /**
     * @param userId
     * @returns CashoutBankAccountRepoResponseItem Ok
     * @throws ApiError
     */
    public listCashoutBankAccounts(
        userId: string,
    ): Observable<Array<CashoutBankAccountRepoResponseItem>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/{userId}/cashoutbankaccounts',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param limit
     * @param last
     * @returns Paginated_CashoutsListItem_ Ok
     * @throws ApiError
     */
    public listCashouts(
        userId: string,
        requestBody: WithReason,
        limit?: number,
        last?: string,
    ): Observable<Paginated_CashoutsListItem_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/cashouts',
            path: {
                'userId': userId,
            },
            query: {
                'limit': limit,
                'last': last,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_AppAnonEmailAddress_ Ok
     * @throws ApiError
     */
    public listAnonEmailsForUserV1(
        userId: string,
        requestBody: WithReason,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_AppAnonEmailAddress_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/anonemail',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_EmailForwardHistory_ Ok
     * @throws ApiError
     */
    public listForwardedEmailsForUserV1(
        userId: string,
        requestBody: WithReason,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_EmailForwardHistory_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/forwardedemail',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Return the original email message if it is on S3
     *
     * The message will be returned as-is as a string that still requires
     * parsing for display, or can be rendered as preformatted text.
     * @param userId
     * @param id
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public getOriginalEmailForUserV1(
        userId: string,
        id: string,
        requestBody: WithReason,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/forwardedemail/{id}',
            path: {
                'userId': userId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * @param requestBody
     * @returns OpenAppGlobalExceptions
     * @throws ApiError
     */
    public topupTestRequest(
        requestBody: UserWalletTopup,
    ): Observable<OpenAppGlobalExceptions> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/topup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param limit
     * @param last
     * @returns Paginated_EnrichedPaymentRequest_ Ok
     * @throws ApiError
     */
    public listUserPaymentRequests(
        userId: string,
        requestBody: WithReason,
        limit: number = 20,
        last?: EncryptedLastItem,
    ): Observable<Paginated_EnrichedPaymentRequest_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/paymentrequest',
            path: {
                'userId': userId,
            },
            query: {
                'limit': limit,
                'last': last,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param id
     * @param requestBody
     * @param limit
     * @param last
     * @returns Paginated_PaymentRequestLog_ Ok
     * @throws ApiError
     */
    public listUserPaymentRequestsLog(
        userId: string,
        id: string,
        requestBody: WithReason,
        limit: number = 20,
        last?: EncryptedLastItem,
    ): Observable<Paginated_PaymentRequestLog_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/paymentrequest/{id}/log',
            path: {
                'userId': userId,
                'id': id,
            },
            query: {
                'limit': limit,
                'last': last,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @param last
     * @param limit
     * @returns Paginated_UserBlock_ Ok
     * @throws ApiError
     */
    public listBlocked(
        userId: string,
        requestBody: WithReason,
        last?: string,
        limit?: number,
    ): Observable<Paginated_UserBlock_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/blocked',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param templateId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public sendTarget(
        userId: string,
        templateId: string,
        requestBody: SendTargetPayload,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/email/{templateId}/send',
            path: {
                'userId': userId,
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param templateId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public sendMass(
        templateId: string,
        requestBody: SendMassPayload,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/email/{templateId}/send',
            path: {
                'templateId': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}