/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AdminOnboardingMerchantRegisterRequest } from '../models/AdminOnboardingMerchantRegisterRequest';
import type { APIGatewayProxyResult } from '../models/APIGatewayProxyResult';
import type { ConsentDefinition } from '../models/ConsentDefinition';
import type { ContactsPage } from '../models/ContactsPage';
import type { EditMerchantUserRequest } from '../models/EditMerchantUserRequest';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { ExternalMerchantData } from '../models/ExternalMerchantData';
import type { FeeScheduleCreate } from '../models/FeeScheduleCreate';
import type { FeeScheduleUpdate } from '../models/FeeScheduleUpdate';
import type { Merchant } from '../models/Merchant';
import type { MerchantContractPaymentPage } from '../models/MerchantContractPaymentPage';
import type { MerchantContractPaymentPageInput } from '../models/MerchantContractPaymentPageInput';
import type { MerchantStatus } from '../models/MerchantStatus';
import type { MerchantStatusUpdate } from '../models/MerchantStatusUpdate';
import type { MerchantUserCreate } from '../models/MerchantUserCreate';
import type { Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_ } from '../models/Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_';
import type { Paginated_Merchant_ } from '../models/Paginated_Merchant_';
import type { Paginated_MerchantUser_ } from '../models/Paginated_MerchantUser_';
import type { ReturnLocation } from '../models/ReturnLocation';
import type { SetMerchantStatus } from '../models/SetMerchantStatus';
import type { TcPp } from '../models/TcPp';
import type { Transaction } from '../models/Transaction';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ManualOnboardingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param taxId
     * @returns Merchant Ok
     * @throws ApiError
     */
    public getMerchantByTaxId(
        taxId: string,
    ): Observable<Merchant> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/taxId/{taxId}',
            path: {
                'taxId': taxId,
            },
        });
    }

    /**
     * @param taxId
     * @returns ExternalMerchantData Ok
     * @throws ApiError
     */
    public getExternalMerchantData(
        taxId: string,
    ): Observable<ExternalMerchantData> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/externalMerchantData/{taxId}',
            path: {
                'taxId': taxId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Merchant Ok
     * @throws ApiError
     */
    public createMerchant(
        requestBody: AdminOnboardingMerchantRegisterRequest,
    ): Observable<Merchant> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param status
     * @param last
     * @param limit
     * @returns Paginated_Merchant_ Ok
     * @throws ApiError
     */
    public listMerchants(
        status?: MerchantStatus,
        last?: string,
        limit?: number,
    ): Observable<Paginated_Merchant_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants',
            query: {
                'status': status,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param merchantId
     * @returns MerchantContractPaymentPage Ok
     * @throws ApiError
     */
    public getContractPaymentPage(
        merchantId: string,
    ): Observable<MerchantContractPaymentPage> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/contractPayment',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setContractPaymentPage(
        merchantId: string,
        requestBody: MerchantContractPaymentPageInput,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/contractPayment',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns ContactsPage Ok
     * @throws ApiError
     */
    public getContactPage(
        merchantId: string,
    ): Observable<ContactsPage> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/contact',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setContactPage(
        merchantId: string,
        requestBody: ContactsPage,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/contact',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns any Ok
     * @throws ApiError
     */
    public listUploadedDocuments(
        merchantId: string,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/documents',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public uploadDocument(
        merchantId: string,
        formData: {
            file: Blob;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/documents',
            path: {
                'merchantId': merchantId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param merchantId
     * @param id
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadDocument(
        merchantId: string,
        id: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/documents/{id}',
            path: {
                'merchantId': merchantId,
                'id': id,
            },
        });
    }

    /**
     * @param merchantId
     * @returns any Ok
     * @throws ApiError
     */
    public getTcPp(
        merchantId: string,
    ): Observable<{
        privacyPolicyUrl: string;
        termsAndConditionsUrl: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/tcPp',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setTcPp(
        merchantId: string,
        requestBody: TcPp,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/tcPp',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns any Ok
     * @throws ApiError
     */
    public listFeeSchedules(
        merchantId: string,
    ): Observable<{
        merchantFeeSchedules: Array<any>;
        defaultFeeSchedules: Array<any>;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/feeSchedule',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createFeeSchedule(
        merchantId: string,
        requestBody: FeeScheduleCreate,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/feeSchedule',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setMerchantStatus(
        merchantId: string,
        requestBody: SetMerchantStatus,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/status',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public setMerchantStatusV2(
        merchantId: string,
        requestBody: MerchantStatusUpdate,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v2/manualOnboarding/merchants/{merchantId}/status',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param serviceId
     * @param startDate
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateFeeSchedule(
        merchantId: string,
        serviceId: string,
        startDate: string,
        requestBody: FeeScheduleUpdate,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/manualOnboarding/merchants/{merchantId}/feeSchedule/service/{serviceId}/startDate/{startDate}',
            path: {
                'merchantId': merchantId,
                'serviceId': serviceId,
                'startDate': startDate,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public saveReturnLocation(
        merchantId: string,
        requestBody?: ReturnLocation,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/returnLocation',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns ReturnLocation Ok
     * @throws ApiError
     */
    public getReturnLocation(
        merchantId: string,
    ): Observable<ReturnLocation> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/returnLocation',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param last
     * @param limit
     * @returns Paginated_MerchantUser_ Ok
     * @throws ApiError
     */
    public listUsers(
        merchantId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_MerchantUser_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/user',
            path: {
                'merchantId': merchantId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns Transaction Ok
     * @throws ApiError
     */
    public createUser(
        merchantId: string,
        requestBody: MerchantUserCreate,
    ): Observable<Transaction> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/user',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param merchantUserId
     * @returns void
     * @throws ApiError
     */
    public deleteUser(
        merchantId: string,
        merchantUserId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/manualOnboarding/merchants/{merchantId}/user/{merchantUserId}',
            path: {
                'merchantId': merchantId,
                'merchantUserId': merchantUserId,
            },
        });
    }

    /**
     * @param merchantId
     * @param merchantUserId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public editUser(
        merchantId: string,
        merchantUserId: string,
        requestBody: EditMerchantUserRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/manualOnboarding/merchants/{merchantId}/user/{merchantUserId}',
            path: {
                'merchantId': merchantId,
                'merchantUserId': merchantUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public create(
        merchantId: string,
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/consentDefinition',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public list(
        merchantId: string,
    ): Observable<Array<ConsentDefinition>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/consentDefinition',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param consentDefinitionId
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public update(
        merchantId: string,
        consentDefinitionId: string,
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/manualOnboarding/merchants/{merchantId}/consentDefinition/{consentDefinitionId}',
            path: {
                'merchantId': merchantId,
                'consentDefinitionId': consentDefinitionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param consentDefinitionId
     * @returns void
     * @throws ApiError
     */
    public remove(
        merchantId: string,
        consentDefinitionId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/manualOnboarding/merchants/{merchantId}/consentDefinition/{consentDefinitionId}',
            path: {
                'merchantId': merchantId,
                'consentDefinitionId': consentDefinitionId,
            },
        });
    }

}