/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { MfaWhitelistItem } from '../models/MfaWhitelistItem';
import type { MfaWhitelistItemType } from '../models/MfaWhitelistItemType';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MfawhitelistService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns MfaWhitelistItem Ok
     * @throws ApiError
     */
    public createWhitelistItem(
        requestBody: MfaWhitelistItem,
    ): Observable<MfaWhitelistItem> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/mfawhitelist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MfaWhitelistItem Ok
     * @throws ApiError
     */
    public getWhitelistItems(): Observable<Array<MfaWhitelistItem>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/mfawhitelist',
        });
    }

    /**
     * @param type
     * @param value
     * @returns MfaWhitelistItem Ok
     * @throws ApiError
     */
    public removeWhitelistItem(
        type: MfaWhitelistItemType,
        value: string,
    ): Observable<MfaWhitelistItem> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/mfawhitelist/{type}/{value}',
            path: {
                'type': type,
                'value': value,
            },
        });
    }

}