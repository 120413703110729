import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';

const SCROLL_DEBOUNCE = 500;

@Component({
  selector: 'oa-layout-scaffold',
  templateUrl: './layout-scaffold.component.html',
})
export class LayoutScaffoldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageTitle: string;
  @Input() loading: boolean;
  @Input() emptyState = false;
  @Input() errorState = false;
  @Input() errorMsg = 'An error occurred';
  @Input() class: string = '';

  @Input() leftDrawerOpened: boolean;
  @Output() leftDrawerOpenedChange = new EventEmitter<boolean>();

  @Input() rightDrawerOpened: boolean;
  @Output() rightDrawerOpenedChange = new EventEmitter<boolean>();

  @Output() scrollEnd = new EventEmitter<void>();

  private kill$ = new Subject<boolean>();

  constructor(public scroll: ScrollDispatcher, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.scroll
      .scrolled(SCROLL_DEBOUNCE)
      .pipe(
        filter(() => !this.loading),
        takeUntil(this.kill$)
      )
      .subscribe((data: CdkScrollable) => {
        const distanceToBottom = data.measureScrollOffset('bottom');
        if (distanceToBottom <= 5) {
          this.scrollEnd.emit();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.kill$.next(true);
  }
}
