import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MobileApiOrderV2 } from 'app/api/generated';

export interface PurchasesState extends EntityState<MobileApiOrderV2, string> {
  selected: MobileApiOrderV2;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'purchases', idKey: 'oaOrderId' })
export class PurchasesStore extends EntityStore<PurchasesState, MobileApiOrderV2, string> {
  constructor() {
    super();
  }
}
