/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Omit_DocumentDetails_id_ } from '../models/Omit_DocumentDetails_id_';
import type { Paginated_ManagedOnboardingListItem_ } from '../models/Paginated_ManagedOnboardingListItem_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantOnboardingsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * Possible queries::
     * - VIEW_ALL
     * - VIEW_REQUIRES_MANUAL_VERIFICATION
     * - VIEW_ABANDONED (started, not finished)
     * ...
     * @param last
     * @returns Paginated_ManagedOnboardingListItem_ Ok
     * @throws ApiError
     */
    public listOnboardings(
        last?: EncryptedLastItem,
    ): Observable<Paginated_ManagedOnboardingListItem_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/merchantOnboardings',
            query: {
                'last': last,
            },
        });
    }

    /**
     * @param onboardingId
     * @param filepath only the part AFTER the slash, without the onboarding ID and slash
     * @returns any Ok
     * @throws ApiError
     */
    public getFile(
        onboardingId: string,
        filepath: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/merchantOnboardings/{onboardingId}/files/{filepath}',
            path: {
                'onboardingId': onboardingId,
                'filepath': filepath,
            },
        });
    }

    /**
     * @param onboardingId
     * @param formData
     * @returns Omit_DocumentDetails_id_ Ok
     * @throws ApiError
     */
    public uploadFile(
        onboardingId: string,
        formData: {
            file: Blob;
        },
    ): Observable<Omit_DocumentDetails_id_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/merchantOnboardings/{onboardingId}/files',
            path: {
                'onboardingId': onboardingId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}