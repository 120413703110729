/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Omit_UserBlackWhitelist_fullPhoneNumber_ } from '../models/Omit_UserBlackWhitelist_fullPhoneNumber_';
import type { Paginated_UserWhitelistResponse_ } from '../models/Paginated_UserWhitelistResponse_';
import type { UserBlackWhitelist } from '../models/UserBlackWhitelist';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BtwhitelistService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns UserBlackWhitelist Ok
     * @throws ApiError
     */
    public createWhitelistItem(
        requestBody: Omit_UserBlackWhitelist_fullPhoneNumber_,
    ): Observable<UserBlackWhitelist> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/btwhitelist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param fullPhoneNumber
     * @param last
     * @param limit
     * @returns Paginated_UserWhitelistResponse_ Ok
     * @throws ApiError
     */
    public getWhitelistItems(
        fullPhoneNumber?: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_UserWhitelistResponse_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/btwhitelist',
            query: {
                'fullPhoneNumber': fullPhoneNumber,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeWhitelistItem(
        requestBody: {
            reason: string;
            fullPhoneNumber: string;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/btwhitelist/remove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeAllWhitelistItems(
        requestBody: WithReason,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/btwhitelist/remove/all',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}