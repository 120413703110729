/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ActivateUserRequest } from '../models/ActivateUserRequest';
import type { AdminUser } from '../models/AdminUser';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UsersService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns AdminUser Ok
     * @throws ApiError
     */
    public whoAmI(): Observable<AdminUser> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/adminusers/whoami',
        });
    }

    /**
     * @returns AdminUser Ok
     * @throws ApiError
     */
    public listUsers(): Observable<Array<AdminUser>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/adminusers',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createUser(
        requestBody: CreateUserRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/adminusers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns AdminUser Ok
     * @throws ApiError
     */
    public updateUser(
        userId: string,
        requestBody: UpdateUserRequest,
    ): Observable<AdminUser> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/adminusers/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param activationToken
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public activateUser(
        activationToken: string,
        requestBody: ActivateUserRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/adminusers/activation',
            headers: {
                'activation-token': activationToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public resetPassword(
        userId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/adminusers/{userId}/resetPassword',
            path: {
                'userId': userId,
            },
        });
    }

}