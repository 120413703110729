/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EnvironmentConfig } from '../models/EnvironmentConfig';
import type { LambdaWithConfigAndExtras } from '../models/LambdaWithConfigAndExtras';
import type { Paginated_LambdaWithConfigAndExtras_ } from '../models/Paginated_LambdaWithConfigAndExtras_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ParametersService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns EnvironmentConfig Ok
     * @throws ApiError
     */
    public getGlobalConfig(): Observable<EnvironmentConfig> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/parameters/config',
        });
    }

    /**
     * @param last
     * @returns Paginated_LambdaWithConfigAndExtras_ Ok
     * @throws ApiError
     */
    public listLambdas(
        last?: string,
    ): Observable<Paginated_LambdaWithConfigAndExtras_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/parameters/lambdas',
            query: {
                'last': last,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateLambdaConfig(
        requestBody: LambdaWithConfigAndExtras,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/parameters/lambdas',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}