/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ApiTranslationKey } from '../models/ApiTranslationKey';
import type { SupportedLanguage } from '../models/SupportedLanguage';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TranslationsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns ApiTranslationKey Ok
     * @throws ApiError
     */
    public keyTranslationKeys(): Observable<Array<ApiTranslationKey>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/translations/keys',
        });
    }

    /**
     * @param locale
     * @returns any Ok
     * @throws ApiError
     */
    public getTranslationForLanguage(
        locale: SupportedLanguage,
    ): Observable<Array<{
        value: string;
        key: ApiTranslationKey;
        language: SupportedLanguage;
    }>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/translations/{locale}',
            path: {
                'locale': locale,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public upsertTranslations(
        requestBody: Array<{
            value?: string;
            key: ApiTranslationKey;
            language: SupportedLanguage;
        }>,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/translations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}