import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AppWalletTrx } from 'app/api/generated';

export interface WalletsTransactionsState extends EntityState<AppWalletTrx, string> {
  selected: AppWalletTrx;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wallets-transactions', idKey: 'trxId' })
export class WalletsTransactionsStore extends EntityStore<WalletsTransactionsState, AppWalletTrx, string> {
  constructor() {
    super();
  }
}
