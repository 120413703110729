import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { LayoutScaffoldComponent } from './components/layout-scaffold/layout-scaffold.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { OaNotificationsComponent } from './notifications/oa-notifications/oa-notifications.component';
import { EnableControlDirective } from './directives/EnableControlDirective';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [CommonModule, MatSidenavModule, MatProgressBarModule, ReactiveFormsModule, MatSnackBarModule, ScrollingModule],
  declarations: [LayoutScaffoldComponent, EmptyStateComponent, ErrorStateComponent, OaNotificationsComponent, EnableControlDirective],
  exports: [
    ReactiveFormsModule,
    LayoutScaffoldComponent,
    EmptyStateComponent,
    ErrorStateComponent,
    OaNotificationsComponent,
    EnableControlDirective,
  ],
})
export class OpenAppModule {}
