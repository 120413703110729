import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { LoginService, LoginRequestBody, OauthRequestBody } from 'app/api/generated';
import { map, pluck, tap } from 'rxjs/operators';
import { UserService } from 'app/core/user/user.service';

const rootPrivilege = 'ROOT';
@Injectable()
export class AuthService {
  constructor(
    private readonly loginService: LoginService,
    private readonly userService: UserService,
  ) {
  }

  getToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  setToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  getOAuthSessionId(): string | null {
    return localStorage.getItem('OAuthSessionId');
  }

  setOAuthSessionId(token: string) {
    localStorage.setItem('OAuthSessionId', token);
  }


  signIn(credentials: LoginRequestBody) {
    return this.loginService.login(credentials).pipe(
      tap((response: { token: string }) => {
        this.setToken(response.token);
      }),
    );
  }

  signOut(): Observable<never> {
    localStorage.removeItem('accessToken');
    return EMPTY;
  }

  signInWithGoogleInit() {
    return this.loginService.oAuthInit();
  }

  loginViaExternalProvider(oauthRequestBody: OauthRequestBody) {
    return this.loginService.oAuthAuth(oauthRequestBody).pipe(
      tap((response: { token: string }) => {
        this.setToken(response.token);
      }),
    );
  }

  isAuthenticated(): Observable<boolean> {
    const token = this.getToken();
    if (!token || AuthUtils.isTokenExpired(token)) {
      return of(false);
    }
    return of(true);
  }

  isAuthorized(permission: string): Observable<boolean> {
    return this.userService.user$.pipe(
      pluck('privileges'),
      map((privileges: string[]) => {
        if (privileges.includes(permission) || privileges.includes(rootPrivilege)) {
          return true;
        }

        return false;
      })
    );
  }
}
