/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { LocalDateTime } from '../models/LocalDateTime';
import type { MerchantOrder } from '../models/MerchantOrder';
import type { OrderFailureType } from '../models/OrderFailureType';
import type { Paginated_MerchantOrder_ } from '../models/Paginated_MerchantOrder_';
import type { Paginated_OrderFailure_ } from '../models/Paginated_OrderFailure_';
import type { PgOrder } from '../models/PgOrder';
import type { QuerySpec } from '../models/QuerySpec';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantOrdersService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_MerchantOrder_ Ok
     * @throws ApiError
     */
    public listOrders(
        merchantId: string,
        integrationProfileId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_MerchantOrder_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/orders',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * Search the orders for the merchant
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgOrder Ok
     * @throws ApiError
     */
    public searchOrders(
        merchantId: string,
        integrationProfileId: string,
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgOrder>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/orders',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param shopOrderId
     * @returns MerchantOrder Ok
     * @throws ApiError
     */
    public getOrder(
        merchantId: string,
        integrationProfileId: string,
        shopOrderId: string,
    ): Observable<MerchantOrder> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/orders/{shopOrderId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'shopOrderId': shopOrderId,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @param start
     * @param end
     * @param type
     * @param last
     * @param limit
     * @returns Paginated_OrderFailure_ Ok
     * @throws ApiError
     */
    public getMerchantOrdersFailures(
        merchantId: string,
        integrationProfileId: string,
        requestBody: WithReason,
        start?: LocalDateTime,
        end?: LocalDateTime,
        type?: Array<OrderFailureType>,
        last?: string,
        limit: number = 20,
    ): Observable<Paginated_OrderFailure_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/orders/failures',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'start': start,
                'end': end,
                'type': type,
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}