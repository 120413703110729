/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BankAccountConfig } from '../models/BankAccountConfig';
import type { BankAccountConfigRequest } from '../models/BankAccountConfigRequest';
import type { IntegratedBanksEnum } from '../models/IntegratedBanksEnum';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BankAccountConfigService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public upsert(
        requestBody: BankAccountConfigRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/bankAccountConfig',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BankAccountConfig Ok
     * @throws ApiError
     */
    public list(): Observable<Array<BankAccountConfig>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bankAccountConfig',
        });
    }

    /**
     * @param bankName
     * @returns BankAccountConfig Ok
     * @throws ApiError
     */
    public getByBank(
        bankName: IntegratedBanksEnum,
    ): Observable<BankAccountConfig> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bankAccountConfig/{bankName}',
            path: {
                'bankName': bankName,
            },
        });
    }

    /**
     * @param bankName
     * @returns BankAccountConfig Ok
     * @throws ApiError
     */
    public deleteBank(
        bankName: IntegratedBanksEnum,
    ): Observable<BankAccountConfig> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/bankAccountConfig/{bankName}',
            path: {
                'bankName': bankName,
            },
        });
    }

}