/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Bank } from '../models/Bank';
import type { BankAssociation } from '../models/BankAssociation';
import type { CreateBankDTO } from '../models/CreateBankDTO';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { FlatBankBranchData } from '../models/FlatBankBranchData';
import type { Omit_CreateBankAssociationDTO_bankId_ } from '../models/Omit_CreateBankAssociationDTO_bankId_';
import type { Paginated_Bank_ } from '../models/Paginated_Bank_';
import type { Paginated_BankAssociation_ } from '../models/Paginated_BankAssociation_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BankService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns Bank Ok
     * @throws ApiError
     */
    public create(
        requestBody: CreateBankDTO,
    ): Observable<Bank> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/bank',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param last
     * @param limit
     * @returns Paginated_Bank_ Ok
     * @throws ApiError
     */
    public list(
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_Bank_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bank',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param bankId
     * @returns void
     * @throws ApiError
     */
    public remove(
        bankId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/bank/{bankId}',
            path: {
                'bankId': bankId,
            },
        });
    }

    /**
     * @param bankId
     * @returns Bank Ok
     * @throws ApiError
     */
    public retrieve(
        bankId: string,
    ): Observable<Bank> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bank/{bankId}',
            path: {
                'bankId': bankId,
            },
        });
    }

    /**
     * @param bankId
     * @param last
     * @param limit
     * @returns Paginated_BankAssociation_ Ok
     * @throws ApiError
     */
    public listBankAssociations(
        bankId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_BankAssociation_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bank/{bankId}/association',
            path: {
                'bankId': bankId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param bankId
     * @param requestBody
     * @returns BankAssociation Ok
     * @throws ApiError
     */
    public createAssociation(
        bankId: string,
        requestBody: Omit_CreateBankAssociationDTO_bankId_,
    ): Observable<BankAssociation> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/bank/{bankId}/association',
            path: {
                'bankId': bankId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param bankId
     * @param id Sort code
     * @returns void
     * @throws ApiError
     */
    public removeAssociation(
        bankId: string,
        id: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/bank/{bankId}/association/{id}',
            path: {
                'bankId': bankId,
                'id': id,
            },
        });
    }

    /**
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public importAssociations(
        formData: {
            dataFile: Blob;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/bank/association/import',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param offset
     * @param limit
     * @returns FlatBankBranchData Ok
     * @throws ApiError
     */
    public listMissingAssociations(
        offset?: number,
        limit: number = 20,
    ): Observable<Array<FlatBankBranchData>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/bank/association/missing',
            query: {
                'offset': offset,
                'limit': limit,
            },
        });
    }

}