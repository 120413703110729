/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ConsentDefinition } from '../models/ConsentDefinition';
import type { DeliveryProvider } from '../models/DeliveryProvider';
import type { Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_ } from '../models/Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_';
import type { Omit_DeliveryProvider_id_ } from '../models/Omit_DeliveryProvider_id_';
import type { Omit_DeliveryProvider_id_or_deliveryOptions_ } from '../models/Omit_DeliveryProvider_id_or_deliveryOptions_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ConsentDefinitionService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public listAll(): Observable<Array<ConsentDefinition>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/consentDefinition',
        });
    }

    /**
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public create(
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/consentDefinition',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public createCustomId(
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/consentDefinition/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: Omit_ConsentDefinition_version_or_consentDefinitionId_or_ownerId_,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/consentDefinition/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ConsentDefinition Ok
     * @throws ApiError
     */
    public getOrThrow(
        id: string,
    ): Observable<ConsentDefinition> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/consentDefinition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public delete(
        id: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/consentDefinition/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public deliveryProviderCreate(
        requestBody: Omit_DeliveryProvider_id_or_deliveryOptions_,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/deliveryProvider',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DeliveryProvider Ok
     * @throws ApiError
     */
    public deliveryProviderGetAll(): Observable<Array<DeliveryProvider>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deliveryProvider',
        });
    }

    /**
     * @param id
     * @returns DeliveryProvider Ok
     * @throws ApiError
     */
    public deliveryProviderGet(
        id: string,
    ): Observable<DeliveryProvider> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/deliveryProvider/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public deliveryProviderUpdate(
        id: string,
        requestBody: Omit_DeliveryProvider_id_,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/deliveryProvider/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any Ok
     * @throws ApiError
     */
    public deliveryProviderRemove(
        id: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/deliveryProvider/{id}',
            path: {
                'id': id,
            },
        });
    }

}