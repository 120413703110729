/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { APIGatewayProxyResult } from '../models/APIGatewayProxyResult';
import type { Paginated_MerchantInvoice_ } from '../models/Paginated_MerchantInvoice_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class InvoicingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_MerchantInvoice_ Ok
     * @throws ApiError
     */
    public list(
        merchantId: string,
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_MerchantInvoice_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/invoicing/{merchantId}/{integrationProfileId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param id
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public download(
        merchantId: string,
        integrationProfileId: string,
        id: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/invoicing/{merchantId}/{integrationProfileId}/{id}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'id': id,
            },
        });
    }

}