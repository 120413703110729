/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { APIGatewayProxyResult } from '../models/APIGatewayProxyResult';
import type { OaService } from '../models/OaService';
import type { Paginated_Settlement_ } from '../models/Paginated_Settlement_';
import type { SettlementKey } from '../models/SettlementKey';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SettlementsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_Settlement_ Ok
     * @throws ApiError
     */
    public list(
        merchantId: string,
        integrationProfileId: string,
        last?: string,
        limit?: number,
    ): Observable<Paginated_Settlement_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/settlements/{merchantId}/{integrationProfileId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @deprecated
     * @param merchantId
     * @param integrationProfileId
     * @param startDate
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public download(
        merchantId: string,
        integrationProfileId: string,
        startDate: string,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/settlements/{merchantId}/{integrationProfileId}/{startDate}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'startDate': startDate,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param startDate
     * @param service
     * @returns APIGatewayProxyResult Ok
     * @throws ApiError
     */
    public downloadV2(
        merchantId: string,
        integrationProfileId: string,
        startDate: string,
        service: OaService,
    ): Observable<APIGatewayProxyResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/settlements/{merchantId}/{integrationProfileId}/{startDate}/{service}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'startDate': startDate,
                'service': service,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public execute(
        requestBody: SettlementKey,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/settlements',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}