/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateLocationRequest } from '../models/CreateLocationRequest';
import type { Location } from '../models/Location';
import type { ReturnLocation } from '../models/ReturnLocation';
import type { UpdateLocationRequest } from '../models/UpdateLocationRequest';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LocationsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @returns Location Ok
     * @throws ApiError
     */
    public listMerchantLocations(
        merchantId: string,
    ): Observable<Array<Location>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/locations/{merchantId}',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public create(
        merchantId: string,
        requestBody: CreateLocationRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/locations/{merchantId}',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public update(
        merchantId: string,
        id: string,
        requestBody: UpdateLocationRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/locations/{merchantId}/{id}',
            path: {
                'merchantId': merchantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param id
     * @returns void
     * @throws ApiError
     */
    public delete(
        merchantId: string,
        id: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/locations/{merchantId}/{id}',
            path: {
                'merchantId': merchantId,
                'id': id,
            },
        });
    }

    /**
     * @param merchantId
     * @returns ReturnLocation Ok
     * @throws ApiError
     */
    public getReturnLocation(
        merchantId: string,
    ): Observable<ReturnLocation> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/returnLocation/{merchantId}',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public saveReturnLocation(
        merchantId: string,
        requestBody?: ReturnLocation,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/returnLocation/{merchantId}',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}