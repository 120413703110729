/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LocalDateTime } from '../models/LocalDateTime';
import type { OrderFailureType } from '../models/OrderFailureType';
import type { Paginated_OrderFailure_ } from '../models/Paginated_OrderFailure_';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OrderFailuresService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @param start
     * @param end
     * @param type
     * @param last
     * @param limit
     * @returns Paginated_OrderFailure_ Ok
     * @throws ApiError
     */
    public list(
        requestBody: WithReason,
        start?: LocalDateTime,
        end?: LocalDateTime,
        type?: Array<OrderFailureType>,
        last?: string,
        limit: number = 20,
    ): Observable<Paginated_OrderFailure_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/order/failures',
            query: {
                'start': start,
                'end': end,
                'type': type,
                'last': last,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}