/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ReturnOffering } from '../models/ReturnOffering';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReturnOfferingsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createOrUpdate(
        requestBody: ReturnOffering,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/returnOfferings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Ok
     * @throws ApiError
     */
    public listAll(): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/returnOfferings',
        });
    }

}