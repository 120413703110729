/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { KycAttemptStatus } from '../models/KycAttemptStatus';
import type { LocalDate } from '../models/LocalDate';
import type { Paginated_ListKycAttempt_ } from '../models/Paginated_ListKycAttempt_';
import type { Paginated_SupportTaskLog_ } from '../models/Paginated_SupportTaskLog_';
import type { RetrieveUserAttemptDetailsResponse } from '../models/RetrieveUserAttemptDetailsResponse';
import type { UpdateAttemptDataPayload } from '../models/UpdateAttemptDataPayload';
import type { UpdateAttemptStatusPayload } from '../models/UpdateAttemptStatusPayload';
import type { UploadTaskAttachmentPayload } from '../models/UploadTaskAttachmentPayload';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AppuserskycService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param status
     * @param last
     * @param limit
     * @param start
     * @param end
     * @returns Paginated_ListKycAttempt_ Ok
     * @throws ApiError
     */
    public listKycAttempts(
        status?: Array<KycAttemptStatus>,
        last?: EncryptedLastItem,
        limit?: number,
        start?: LocalDate,
        end?: LocalDate,
    ): Observable<Paginated_ListKycAttempt_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/kyc/attempts',
            query: {
                'status': status,
                'last': last,
                'limit': limit,
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RetrieveUserAttemptDetailsResponse Ok
     * @throws ApiError
     */
    public updateKycAttemptStatus(
        id: string,
        requestBody: UpdateAttemptStatusPayload,
    ): Observable<RetrieveUserAttemptDetailsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/kyc/attempts/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public uploadKycAttemptAttachment(
        id: string,
        requestBody: UploadTaskAttachmentPayload,
    ): Observable<{
        id: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/kyc/attempts/{id}/attachment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RetrieveUserAttemptDetailsResponse Ok
     * @throws ApiError
     */
    public updateKycAttemptData(
        id: string,
        requestBody: UpdateAttemptDataPayload,
    ): Observable<RetrieveUserAttemptDetailsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/v1/appusers/kyc/attempts/{id}/data',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param last
     * @param limit
     * @returns Paginated_ListKycAttempt_ Ok
     * @throws ApiError
     */
    public listUserKycAttempts(
        userId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_ListKycAttempt_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/{userId}/kyc/attempts',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param userId
     * @param last
     * @param limit
     * @returns Paginated_SupportTaskLog_ Ok
     * @throws ApiError
     */
    public listUserKycAttemptsLogs(
        userId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_SupportTaskLog_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/appusers/{userId}/kyc/attempts/logs',
            path: {
                'userId': userId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns RetrieveUserAttemptDetailsResponse Ok
     * @throws ApiError
     */
    public retrieveUserKycDetails(
        userId: string,
        requestBody: WithReason,
    ): Observable<RetrieveUserAttemptDetailsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/appusers/{userId}/kyc/attempts/details',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}