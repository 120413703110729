import {IEnvironment} from 'environments/IEnvironment';

export const environment: IEnvironment = {
  production: false,
  apiUrl: 'https://admin-panel-api.int.open-pay.com/adminPanel',
  backdoorPanelApiUrl: 'https://api.int.open-pay.com/backdoor',
  swaggerUrl: 'https://static.int.open-pay.com/swagger/adminPanel/swagger.json',
  pagination: {
    page: 0,
    size: 30,
  },
};
