/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Omit_UserBlackWhitelist_fullPhoneNumber_ } from '../models/Omit_UserBlackWhitelist_fullPhoneNumber_';
import type { Paginated_UserBlackWhitelist_ } from '../models/Paginated_UserBlackWhitelist_';
import type { UserBlackWhitelist } from '../models/UserBlackWhitelist';
import type { UserBlackWhiteListInput } from '../models/UserBlackWhiteListInput';
import type { WithReason } from '../models/WithReason';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BlacklistService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns UserBlackWhitelist Ok
     * @throws ApiError
     */
    public createBlackelistItem(
        requestBody: Omit_UserBlackWhitelist_fullPhoneNumber_,
    ): Observable<UserBlackWhitelist> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/blacklist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param fullPhoneNumber
     * @param last
     * @param limit
     * @returns Paginated_UserBlackWhitelist_ Ok
     * @throws ApiError
     */
    public getBlacklistItems(
        fullPhoneNumber?: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_UserBlackWhitelist_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/blacklist',
            query: {
                'fullPhoneNumber': fullPhoneNumber,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeBlacklistItem(
        requestBody: UserBlackWhiteListInput,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/blacklist/remove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public removeAllBlacklistItems(
        requestBody: WithReason,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/blacklist/remove/all',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}