import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Notification {
  message: string;
  action: string;
  duration: number;
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class OaNotificationsService {
  private notifications = new Subject<Notification>();

  get notifications$() {
    return this.notifications.asObservable();
  }

  push(message: string, color = 'success', action = '', duration = 3000) {
    this.notifications.next({
      message,
      action,
      duration,
      color,
    });
  }
}
