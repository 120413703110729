import { Injectable } from '@angular/core';
import { AppusersService, MobileApiOrderV2 } from 'app/api/generated';
import { StateManagementService } from '@openapp/state-management/state-management.service';
import { environment } from 'environments/environment';
import { PurchasesState, PurchasesStore } from './purchases.store';

@Injectable({
  providedIn: 'root',
})
export class PurchasesService extends StateManagementService<PurchasesState> {
  last: string;
  pagination = environment.pagination;

  constructor(protected readonly store: PurchasesStore, private readonly backendAppUser: AppusersService) {
    super(store);
    this.store.setLoading(false);
    this.store.setError(null);
  }

  async getUserOrders(userId: string) {
    await this.execute(
      this.backendAppUser.getUserOrders(userId, { reason: 'LOAD MORE' }, this.last, this.pagination.size),
      async (data) => {
        const { values, last } = data;
        this.last = last;
        this.store.add(values);
      }
    );
  }

  selectActive(purchase: MobileApiOrderV2) {
    this.store.update({ selected: purchase });
  }

  removeStoreResults() {
    this.store.set([]);
  }
}
