import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const permission = (route.data as { role: string })?.role;

    return combineLatest([this._check('/'), this.checkAuthorization(permission)])
      .pipe(
        map(([authenticated, authorized]) => authenticated && authorized)
      );
  }

  private checkAuthorization(permission: string): Observable<boolean> {
    return this._authService.isAuthorized(permission)
      .pipe(
        switchMap((authorized) => {
          if (!authorized) {
            this._router.navigate(['forbidden']);

            return of(false);
          }

          return of(true);
        }),
      )
  }

  private _check(redirectURL: string): Observable<boolean> {
    return this._authService.isAuthenticated()
      .pipe(
        switchMap((authenticated) => {

          if (!authenticated) {
            this._router.navigate(['sign-in'], {queryParams: {redirectURL}});
            return of(false);
          }

          return of(true);
        }),
      );
  }
}
