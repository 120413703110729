/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LoginRequestBody } from '../models/LoginRequestBody';
import type { OauthRequestBody } from '../models/OauthRequestBody';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LoginService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public login(
        requestBody: LoginRequestBody,
    ): Observable<{
        token: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Ok
     * @throws ApiError
     */
    public oAuthInit(): Observable<{
        sessionId: string;
        authUrl: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/login/oauth/init',
        });
    }

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public oAuthAuth(
        requestBody: OauthRequestBody,
    ): Observable<{
        token: string;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/login/oauth/auth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}