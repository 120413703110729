import {Pipe, PipeTransform} from '@angular/core';
import {Address, MerchantRepresentative} from 'app/api/generated';

@Pipe({
  name: 'representativesFormatPipe',
  pure: true,
})
export class RepresentativesFormatPipe implements PipeTransform {
  transform(representative: MerchantRepresentative): string {
    return `${representative.firstName} ${representative.lastName} ${representative.role || ''} ${representative.pesel || ''}`;
  }
}
