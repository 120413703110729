/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EditMerchantListItem } from '../models/EditMerchantListItem';
import type { SaveMerchantListItem } from '../models/SaveMerchantListItem';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantListService {

    constructor(private readonly http: HttpClient) {}

    /**
     * The merchants that might be included in the list
     * @returns EditMerchantListItem Ok
     * @throws ApiError
     */
    public listMerchantsV1(): Observable<Array<EditMerchantListItem>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchantList',
        });
    }

    /**
     * Save a new list
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public saveMerchantListV1(
        requestBody: Array<SaveMerchantListItem>,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/merchantList',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * The active list
     * @returns EditMerchantListItem Ok
     * @throws ApiError
     */
    public activeMerchantListV1(): Observable<Array<EditMerchantListItem>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchantList/active',
        });
    }

}