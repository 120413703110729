/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { KycPhoneWhitelistItem } from '../models/KycPhoneWhitelistItem';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class KycPhoneWhitelistService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns KycPhoneWhitelistItem Ok
     * @throws ApiError
     */
    public list(): Observable<Array<KycPhoneWhitelistItem>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/kyc/phoneWhitelist',
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public add(
        requestBody: KycPhoneWhitelistItem,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/phoneWhitelist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param phoneNumber
     * @returns void
     * @throws ApiError
     */
    public remove(
        phoneNumber: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/kyc/phoneWhitelist/{phoneNumber}',
            path: {
                'phoneNumber': phoneNumber,
            },
        });
    }

}