import { LOCALE_ID, NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { AddressFormatPipe } from './pipes/address-format.pipe';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { CompanyComponent } from 'app/modules/merchants/merchants-details/company/company.component';
import { PhonePipe } from './pipes/phone-format.pipe';
import { TaxPipe } from './pipes/tax-format.pipe';
import { RepresentativesFormatPipe } from './pipes/representantive.pipe';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FuseAlertModule } from '@fuse/components/alert';
import { IbanPipe } from './pipes/iban-format.pipe';
import { SelectIntegrationProfileComponent } from './components/select-integration-profile/select-integration-profile.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localePL from '@angular/common/locales/pl';
import { DetectScrollToEndDirective } from './directives/detect-scroll-to-end-directive';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

registerLocaleData(localeFr);
registerLocaleData(localePL);

const IMPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  FuseAlertModule,
  TranslocoCoreModule,
  MatDialogModule,
];
const COMPONENTS = [CompanyComponent, SelectIntegrationProfileComponent, DetectScrollToEndDirective];
const PIPES = [CurrencyFormatPipe, AddressFormatPipe, PhonePipe, TaxPipe, RepresentativesFormatPipe, IbanPipe];

@NgModule({
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...COMPONENTS, ...PIPES],
  declarations: [...PIPES, ...COMPONENTS],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
})
export class SharedModule {}
