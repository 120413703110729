/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { SanctionPhysicalEntitySearch } from '../models/SanctionPhysicalEntitySearch';
import type { SanctionSearchResult_SanctionPhysicalEntity_ } from '../models/SanctionSearchResult_SanctionPhysicalEntity_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class SanctionListService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns void
     * @throws ApiError
     */
    public reload(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sanctionList/reload',
        });
    }

    /**
     * @param requestBody
     * @returns SanctionSearchResult_SanctionPhysicalEntity_ Ok
     * @throws ApiError
     */
    public search(
        requestBody: SanctionPhysicalEntitySearch,
    ): Observable<SanctionSearchResult_SanctionPhysicalEntity_> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/sanctionList/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteIndex(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/sanctionList/index',
        });
    }

}