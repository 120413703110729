import {Pipe, PipeTransform} from '@angular/core';
import {Address} from 'app/api/generated';

@Pipe({
  name: 'addressFormatPipe',
  pure: true,
})
export class AddressFormatPipe implements PipeTransform {
  transform(address: Address): string {
    return `${address.street} ${address.streetNo}${address.apartmentNo ? '/' + address.apartmentNo : ''} ${address.postalCode} ${
      address.city
    }`;
  }
}
