import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationProfileResponse } from 'app/api/generated';
import { OnboardingManagementService } from 'app/modules/onboarding-management/data-access/onboarding-management.service';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-integration-profile',
  templateUrl: './select-integration-profile.component.html',
})
export class SelectIntegrationProfileComponent implements OnInit {
  @Input() merchantId: string;
  @Output() profileChanged = new EventEmitter<string>();
  @Output() selectedMerchantId = new EventEmitter<string>();

  private readonly _lsProfileIdKey = 'selectedProfileId';
  public profiles$: Observable<IntegrationProfileResponse[]>;
  public profileId: string;

  constructor(private readonly onboardingManagementService: OnboardingManagementService) {}

  ngOnInit(): void {
    // const profilesExist = this.onboardingManagementService.integrationProfilesSource$.value;
    // if (!profilesExist || !profilesExist.length) {
    //   this.profiles$ = this.onboardingManagementService.getIntegrationProfile(this.merchantId).pipe(tap(this.run));
    // } else {
    //   this.profiles$ = this.onboardingManagementService.integrationProfilesSource$.pipe(tap(this.run));
    // }

    this.profiles$ = this.onboardingManagementService.getIntegrationProfile(this.merchantId).pipe(tap(this.run));
  }

  private run = (profiles) => {
    if (profiles.length && !this.profileId) {
      const ls = localStorage.getItem(this._lsProfileIdKey);
      const exist = profiles.find((a) => a.integrationProfileId === ls);
      this.profileId = exist ? ls : profiles[0].integrationProfileId;
      localStorage.setItem(this._lsProfileIdKey, this.profileId);
      this.profileChanged.emit(this.profileId);
      this.selectedMerchantId.emit(exist.merchantId);
    }
  };

  public changedProfile(profile: any) {
    localStorage.setItem(this._lsProfileIdKey, this.profileId);
    this.profileChanged.emit(this.profileId);
  }
}
