/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AdminTransferOrderRequest } from '../models/AdminTransferOrderRequest';
import type { BankAccountNotConfiguredException } from '../models/BankAccountNotConfiguredException';
import type { OpenAppGlobalExceptions } from '../models/OpenAppGlobalExceptions';
import type { TransferOrderException } from '../models/TransferOrderException';
import type { TrasferAmountHigherThanBalance } from '../models/TrasferAmountHigherThanBalance';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class GenericLayerService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public transferByAdminPanel(
        requestBody: AdminTransferOrderRequest,
    ): Observable<(OpenAppGlobalExceptions | TransferOrderException | BankAccountNotConfiguredException | TrasferAmountHigherThanBalance)> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/bank/genericLayer/transferOrderByAdminPanel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}