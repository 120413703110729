/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateCredentialOneTimeResponse } from '../models/CreateCredentialOneTimeResponse';
import type { CreateCredentialRequest } from '../models/CreateCredentialRequest';
import type { InsightsLogType } from '../models/InsightsLogType';
import type { IntegrationProfileResponse } from '../models/IntegrationProfileResponse';
import type { OpenPayBasket } from '../models/OpenPayBasket';
import type { Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_ } from '../models/Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_';
import type { Record_string_any_ } from '../models/Record_string_any_';
import type { TestGetBasketConnectionRequest } from '../models/TestGetBasketConnectionRequest';
import type { UpdateIntegrationProfileRequest } from '../models/UpdateIntegrationProfileRequest';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class IntegrationProfilesService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param merchantId
     * @param requestBody
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public createIntegrationProfile(
        merchantId: string,
        requestBody: Pick_IntegrationProfile_integrationProfileName_or_websiteUrl_or_logoUrl_,
    ): Observable<IntegrationProfileResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public listIntegrationProfiles(
        merchantId: string,
    ): Observable<Array<IntegrationProfileResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles',
            path: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @returns IntegrationProfileResponse Ok
     * @throws ApiError
     */
    public updateIntegrationProfile(
        merchantId: string,
        integrationProfileId: string,
        requestBody: UpdateIntegrationProfileRequest,
    ): Observable<IntegrationProfileResponse> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Permanently deactivates the profile
     * @param merchantId
     * @param integrationProfileId
     * @returns void
     * @throws ApiError
     */
    public deactivateIntegrationProfile(
        merchantId: string,
        integrationProfileId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * Can only create a maximum of 20 active credentials per integration profile
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @returns CreateCredentialOneTimeResponse Ok
     * @throws ApiError
     */
    public createCredential(
        merchantId: string,
        integrationProfileId: string,
        requestBody: CreateCredentialRequest,
    ): Observable<CreateCredentialOneTimeResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/openAppCredentials',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param credentialId
     * @returns void
     * @throws ApiError
     */
    public deleteCredential(
        merchantId: string,
        integrationProfileId: string,
        credentialId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/openAppCredentials/{credentialId}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'credentialId': credentialId,
            },
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @returns OpenPayBasket Ok
     * @throws ApiError
     */
    public testGetBasketConnection(
        merchantId: string,
        integrationProfileId: string,
        requestBody: TestGetBasketConnectionRequest,
    ): Observable<OpenPayBasket> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/testConnection/getBasket',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param integrationProfileId
     * @param logType
     * @param needle
     * @returns Record_string_any_ Ok
     * @throws ApiError
     */
    public getLogs(
        merchantId: string,
        integrationProfileId: string,
        logType: InsightsLogType,
        needle: string,
    ): Observable<Array<Record_string_any_>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/manualOnboarding/merchants/{merchantId}/integrationProfiles/{integrationProfileId}/logs/{logType}/{needle}',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
                'logType': logType,
                'needle': needle,
            },
        });
    }

}