/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateOrUpdateConsentDefinitionRequest } from '../models/CreateOrUpdateConsentDefinitionRequest';
import type { GetTermsAndConditionsResponse } from '../models/GetTermsAndConditionsResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantTermsAndConditionsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public createOrUpdateMerchantTc(
        requestBody: CreateOrUpdateConsentDefinitionRequest,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/merchantTermsAndConditions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetTermsAndConditionsResponse Ok
     * @throws ApiError
     */
    public getMerchantTc(): Observable<GetTermsAndConditionsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/merchantTermsAndConditions',
        });
    }

}