/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateKycTestCase } from '../models/CreateKycTestCase';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { IdDocumentType } from '../models/IdDocumentType';
import type { KycTestCase } from '../models/KycTestCase';
import type { Paginated_ListKycTestCase_ } from '../models/Paginated_ListKycTestCase_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class KycTestCasesService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param type
     * @param last
     * @param limit
     * @returns Paginated_ListKycTestCase_ Ok
     * @throws ApiError
     */
    public list(
        type?: IdDocumentType,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_ListKycTestCase_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/kyc/testCase',
            query: {
                'type': type,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns KycTestCase Ok
     * @throws ApiError
     */
    public add(
        requestBody: CreateKycTestCase,
    ): Observable<KycTestCase> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/testCase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns KycTestCase Ok
     * @throws ApiError
     */
    public remove(
        id: string,
    ): Observable<KycTestCase> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/kyc/testCase/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns KycTestCase Ok
     * @throws ApiError
     */
    public retrieve(
        id: string,
    ): Observable<KycTestCase> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/kyc/testCase/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param forceExtraction
     * @returns KycTestCase Ok
     * @throws ApiError
     */
    public run(
        id: string,
        forceExtraction?: boolean,
    ): Observable<KycTestCase> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/testCase/{id}/run',
            path: {
                'id': id,
            },
            query: {
                'forceExtraction': forceExtraction,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public runAll(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/kyc/testCase/run',
        });
    }

}