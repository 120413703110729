import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OnboardingState, OnboardingStore } from 'app/modules/onboarding-management/data-access/onboarding.store';

@Injectable({ providedIn: 'root' })
export class OnboardingQuery extends QueryEntity<OnboardingState> {
  loading$ = this.selectLoading();
  error$ = this.selectError();
  onboardings$ = this.selectAll();
  active$ = this.selectActive();
  last$ = this.select((state) => state.last);
  selected$ = this.select((store) => store.selected);

  constructor(protected store: OnboardingStore) {
    super(store);
  }
}
