/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LocalDate } from '../models/LocalDate';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ReportingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param start
     * @param end
     * @returns string Ok
     * @throws ApiError
     */
    public orders(
        start: LocalDate,
        end: LocalDate,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/orders/csv',
            query: {
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param start
     * @param end
     * @returns string Ok
     * @throws ApiError
     */
    public topups(
        start: LocalDate,
        end: LocalDate,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/topups/csv',
            query: {
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param start
     * @param end
     * @returns string Ok
     * @throws ApiError
     */
    public cashouts(
        start: LocalDate,
        end: LocalDate,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/cashouts/csv',
            query: {
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @param start
     * @param end
     * @returns string Ok
     * @throws ApiError
     */
    public transactions(
        start: LocalDate,
        end: LocalDate,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/transactions/csv',
            query: {
                'start': start,
                'end': end,
            },
        });
    }

}